import { Component, OnInit } from '@angular/core';
import { ConfirmationService } from 'primeng/api';
import { StaticFields } from '../../shared/staticFields';
import { MsalService } from '@azure/msal-angular';
import { HomeService, CustomMessageService } from '../../services';
import { Home } from '../../models/home';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  hideEditorCtrl = false;
  homeScreenData: Home = {
    id: null,
    box1: '',
    box2: '',
    box3: '',
    box4: '',
    userId: '',
    actionBy: '',
    actionOn: new Date()
  };
  isAdmin = false;
  isSTiRAdmin: boolean = false;
  isSTiRUser: boolean = false;

  constructor(private confirmationService: ConfirmationService, private msalService: MsalService, private homeService: HomeService,
    public customMessageService: CustomMessageService,
  ) { }

  ngOnInit(): void {

    let allAccounts = this.msalService.instance.getAllAccounts()
    if (allAccounts.length > 0) {
      let account = allAccounts[0];
      const roles = account.idTokenClaims!.roles;
      this.isSTiRAdmin = roles!.find(ele => ele == "STiR.Admin") ? true : false;
      this.isSTiRUser = roles!.find(ele => ele == "STiR.User") ? true : false;
    }

    this.getHomePageData();
  }

  editContent() {
    this.hideEditorCtrl = true;
  }

  cancelEdit() {
    this.confirmationService.confirm({
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      message: 'Are you sure you want to cancel the changes made?',
      accept: () => {
        this.hideEditorCtrl = false;
        this.homeScreenData = StaticFields.homeScreenData ? JSON.parse(JSON.stringify(StaticFields.homeScreenData)) : this.homeScreenData;
        //Actual logic to perform a confirmation
      }
    });
  }

  getHomePageData() {
    this.homeService.fetchHomeById(StaticFields.appUserId).subscribe((result: any) => {
      StaticFields.homeScreenData = result.sTiRResult as Home;
      this.homeScreenData = JSON.parse(JSON.stringify(StaticFields.homeScreenData));
      this.hideEditorCtrl = false;
      StaticFields.appUserId = StaticFields.homeScreenData.userId;
    });
  }

  saveHomePageData() {
    this.homeScreenData.userId = StaticFields.appUserId;
    this.homeService.saveHome(this.homeScreenData).subscribe({
      next: (result: any) => {
        if (!result.isError) {
          StaticFields.homeScreenData = this.homeScreenData;
          this.homeScreenData = JSON.parse(JSON.stringify(StaticFields.homeScreenData));
          this.hideEditorCtrl = false;
          this.customMessageService.showMessage({ 'severity': 'success', summary: 'success', detail: result.message, data: 'Home' });
        }
      }
      ,
      error: (err: any) => {
        this.customMessageService.showMessage({ 'severity': 'error', summary: 'Error', detail: err.message, data: 'Home' });
      }
    });
  }
}