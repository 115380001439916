import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { MSAL_INSTANCE, MSAL_GUARD_CONFIG, MSAL_INTERCEPTOR_CONFIG } from '@azure/msal-angular';
import { PublicClientApplication, LogLevel } from '@azure/msal-browser';
import { MsalSettings } from './app/models/msalSettings';
import { SettingService } from './app/services/setting.service';
import "ag-grid-enterprise";
import { LicenseManager } from 'ag-grid-enterprise';
LicenseManager.setLicenseKey("CompanyName=SHI International Corp._on_behalf_of_Pioneer Hi-Bred Intl Inc. (US, Johnston, IA 50131),LicensedGroup=DSI,LicenseType=MultipleApplications,LicensedConcurrentDeveloperCount=25,LicensedProductionInstancesCount=0,AssetReference=AG-036752,SupportServicesEnd=2_September_2024_[v2]_MTcyNTIzMTYwMDAwMA==3211b93f03e1e90c9f013132ac4acca8");
if (environment.production) {
  enableProdMode();
}

function msalLoggerCallback(logLevel: LogLevel, message: string) { console.log(`[MSAL][${LogLevel[logLevel]}]: ${message}`); }
fetch('/assets/settings.json')
  .then(response => response.json())
  .then((msalSettings: MsalSettings) => {
    if (environment.production) {
      enableProdMode();
    }

    platformBrowserDynamic([
      {provide: MSAL_INSTANCE, useValue: new PublicClientApplication({
      auth: msalSettings.msal.config.auth,
      cache: msalSettings.msal.config.cache,
      system: {
        loggerOptions: {
          loggerCallback: msalLoggerCallback,
          logLevel: LogLevel.Warning,
          piiLoggingEnabled: false
        }
      }
    })
  },
  {
    provide: MSAL_GUARD_CONFIG, useValue: { ...msalSettings.msal.guard }
  },
  {
    provide: MSAL_INTERCEPTOR_CONFIG,
    useValue: { protectedResourceMap: new Map(msalSettings.msal.interceptor.protectedResourceMap) }
  },
       {
      provide: SettingService, useValue: { ...msalSettings }
    }]).bootstrapModule(AppModule)
      .catch(err => console.error(err));
  });
