import { AfterViewInit, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MSAL_GUARD_CONFIG, MsalBroadcastService, MsalGuardConfiguration, MsalService } from '@azure/msal-angular';
import { AuthenticationResult, EventMessage, EventType, InteractionStatus, RedirectRequest } from '@azure/msal-browser';
import { filter, Subject, takeUntil } from 'rxjs';
import { StaticFields } from './shared/staticFields';
import * as FileSaver from 'file-saver';
import { UserClaim, Home, ApplicationSettings, Version } from './models';
import { SettingService, HomeService, AppService, CustomMessageService, LoggingService } from './services';
import { environment } from 'src/environments/environment';
import { DOCUMENT } from '@angular/common';
import { DialogService } from 'primeng/dynamicdialog';
import { Router, NavigationStart } from '@angular/router';
import { NgxCaptureService } from 'ngx-capture';
import { HttpParams } from '@angular/common/http';
import { AppConstants } from './shared/constants';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy, AfterViewInit {
  public versionNumber = environment.appVersion;
  public environment = "";
  title = 'STiR_UI';
  imageSrc: string = "";
  isIframe: boolean = false;
  isNavVisible: boolean = false;
  private readonly _destroying$ = new Subject<void>();
  loginDisplay: boolean = false;
  userClaim: any = null;
  errors: any[] = JSON.parse(localStorage.getItem("errors") || "[]");
  roles: string[] | undefined;
  isSTiRAdmin: boolean = false;
  isSTiRUser: boolean = false;
  isSTiRView: boolean = false;
  translationForGrid: any[] = [];
  showMapModal: boolean = false;
  selectedMapHeaders: any[] = []
  claims: UserClaim = new UserClaim();
  homeScreenData: Home = {
    id: null,
    box1: '',
    box2: '',
    box3: '',
    box4: '',
    userId: '',
    actionBy: '',
    actionOn: new Date()
  };

  constructor(
    @Inject(SettingService) private config: ApplicationSettings,
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private msalService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    public dialogService: DialogService,
    private router: Router,
    private captureService: NgxCaptureService,
    @Inject(DOCUMENT) private document: Document,
    private appService: AppService,
    private logService: LoggingService,
    private homeService: HomeService,
    public customMessageService: CustomMessageService
  ) {

    if (this.config.environment.name.toUpperCase() === 'LOCAL' || this.config.environment.name.toUpperCase() === 'DEV'
      || this.config.environment.name.toUpperCase() === 'QA' || this.config.environment.name.toUpperCase() === 'TR') {
      this.environment = "(" + this.config.environment.name + ")";
    }

    appService.getVersion().subscribe((result: Version) => {
      this.versionNumber = result.version;
    });

    this.customMessageService.errorMsgs$.subscribe(result => {
      this.logService.setScreenName(result.data);
      if (result.severity === 'error') {
        setTimeout(() => {
          this.captureService.getImage(document.body, true).subscribe((img: any) => {
            result['img'] = img;
            this.logService.setLoggingObject(AppConstants.Error, result.detail, StaticFields.email, img);
            this.logService.saveLoggingData(StaticFields.email).subscribe();
            this.appendError(result);
          })
        }, 1000);
      } else
        this.appendError(result);
    });
  }


  ngOnInit(): void {

    this.isIframe = window !== window.parent && !window.opener;
    this.msalService.instance.enableAccountStorageEvents();
    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.ACCOUNT_ADDED || msg.eventType === EventType.ACCOUNT_REMOVED),
      )
      .subscribe(async (result: EventMessage) => {
        if (this.msalService.instance.getAllAccounts().length === 0) {
          // await this.msalService.instance.handleRedirectPromise();
          await this.msalService.instance.loginRedirect();
          window.location.pathname = "/";
        } else {
          this.setLoginDisplay();
        }
      });

    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None)
      )
      .subscribe(() => {
        this.checkAndSetActiveAccount();
      });

    if (this.msalService.instance.getActiveAccount()) {
      this.getRoles();
      if (this.roles!.length > 0) {
        this.isNavVisible = true;
        if (window.location.pathname === "/pagenotfound" || window.location.pathname === "/underconstruction") {
          this.router.navigate(['home']);
        }
      }
      this.setOnLoad();
    } else {
      this.msalBroadcastService.msalSubject$
        .pipe(
          filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS),
          takeUntil(this._destroying$)
        )
        .subscribe((result: EventMessage) => {
          const payload = result.payload as AuthenticationResult;
          this.msalService.instance.setActiveAccount(payload.account);
          if (this.msalService.instance.getAllAccounts().length === 0) {
            this.isNavVisible = false;
          } else {
            this.getRoles();
            if (this.roles!.length > 0)
              this.isNavVisible = true;;
          }
          if (this.isNavVisible) {
            this.setOnLoad();
          }
        });
    }

  }

  ngAfterViewInit(): void {
    //const elements = document.getElementsByClassName("footer-container");
    // while (elements.length > 0) {
    //   elements[0]?.parentNode.removeChild(elements[0]);
    // }
  }

  setLoginDisplay() {
    this.loginDisplay = this.msalService.instance.getAllAccounts().length > 0;
  }

  private setOnLoad() {
    if (StaticFields.userInfo === undefined || StaticFields.userInfo === null) {
      this.appService.getUserInfo().subscribe((result: any) => {
        StaticFields.userInfo = result;
        this.setInitialData();
      });
    } else {
      this.setInitialData();
    }
  }

  setInitialData() {
    if (StaticFields.homeScreenData === undefined || StaticFields.homeScreenData === null) {
      let params = new HttpParams();
      params = params.append('userName', StaticFields.userInfo.onPremisesSamAccountName);
      this.homeService.getUserDetails().subscribe((result: any) => {
        if (result.success) {
          StaticFields.homeScreenData = result.data ? result.data : this.homeScreenData
        }
      });
    }
  }

  appendError(result: any) {
    let errors = JSON.parse(localStorage.getItem("errors") || "[]");
    if (errors.length === 10) {
      errors.pop();
      errors.unshift(result);
    } else {
      errors.unshift(result);
    }
    this.errors = errors;
    localStorage.setItem("errors", JSON.stringify(errors));
  }

  clearErrors() {
    this.errors = [];
    localStorage.setItem("errors", JSON.stringify(this.errors));
  }

  bgColor(rowData: any): any {
    if (rowData.severity === "success") {
      return "#b7d8b7";
    } else if (rowData.severity === "error") {
      return "#f8b7bd";
    } else if (rowData.severity === "warn") {
      return "#ffe399";
    } else if (rowData.severity === "info") {
      return "#7fbcec";
    }
  }

  color(rowData: any): any {
    if (rowData.severity === "success") {
      return "#000000";
    } else if (rowData.severity === "error") {
      return "#000000";
    } else if (rowData.severity === "warn") {
      return "#000000";
    } else if (rowData.severity === "info") {
      return "#000000";
    }
  }


  public login() {
    if (this.msalGuardConfig.authRequest) {
      this.msalService.loginRedirect({ ...this.msalGuardConfig.authRequest } as RedirectRequest).subscribe({
        next: (res) => {
        },
        error: (error) => {
          console.log(error);
        },
      });
    } else {
      this.msalService.loginRedirect();
    }
  }

  checkAndSetActiveAccount() {
    let activeAccount = this.msalService.instance.getActiveAccount();

    if (!activeAccount && this.msalService.instance.getAllAccounts().length > 0) {
      let accounts = this.msalService.instance.getAllAccounts();
      this.msalService.instance.setActiveAccount(accounts[0]);

    }
    if (activeAccount) {
      const url = this.document.location.toString();
      const pageName = url.substring(url.lastIndexOf("/"), url.length);

      var user: any = this.msalService.instance.getActiveAccount();
      StaticFields.email = user.username;
      this.getRoles();

    } else {

    }
  }

  public getRoles() {
    let allAccounts = this.msalService.instance.getAllAccounts();
    if (allAccounts.length > 0) {
      let account = allAccounts[0];
      this.roles = account.idTokenClaims!.roles;
      this.isSTiRAdmin = this.roles!.find(ele => ele == "STiR.Admin") ? true : false;
      this.isSTiRUser = this.roles!.find(ele => ele == "STiR.User") ? true : false;
      this.isSTiRView = this.roles!.find(ele => ele == "STiR.View") ? true : false;

      if (!this.isSTiRAdmin && !this.isSTiRUser && !this.isSTiRView) {
        this.isNavVisible = false;
        this.router.navigate(['pagenotfound']);
      }
    } else {
      this.isNavVisible = false;
      this.router.navigate(['pagenotfound']);
    }
  }

  downloadImage(data: any) {
    const blob = this.DataURIToBlob(data.img);
    FileSaver.saveAs(blob, "screenshot.png");
  }

  DataURIToBlob(dataURI: string) {
    const splitDataURI = dataURI.split(',');
    const byteString = splitDataURI[0].indexOf('base64') >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1])
    const mimeString = splitDataURI[0].split(':')[1].split(';')[0]

    const ia = new Uint8Array(byteString.length)
    for (let i = 0; i < byteString.length; i++)
      ia[i] = byteString.charCodeAt(i)

    return new Blob([ia], { type: mimeString })
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }
}
