import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { CustomMessageService } from '../services/customMessage.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(private customMessageService: CustomMessageService) {

    }
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(
            catchError((error: HttpErrorResponse) => {
                if (error.status === 403) {
                    this.customMessageService.showMessage({ 'severity': 'error', summary: 'Error', detail: error.error, data: 'Error Interceptor' });
                    return of();
                }
                // Rethrow other errors
                return throwError(() => error);
            })
        );
    }
}
