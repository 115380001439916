<span #screen>
	<p-toast [style]="{marginTop: '80px','position': 'fixed'}" [autoZIndex]="true" [baseZIndex]="999999"></p-toast>
	<res-layout>
		<res-header>
			<res-logo appName="Stored Treatmint Recipes {{this.environment}}" [showAppName]="true">
				<img res-logo src="../favicon.ico" width="50" height="50" />
			</res-logo>
		</res-header>
		<res-navigation *ngIf="isNavVisible" style="margin-bottom: 5px;">
			<res-navigation-item>
				<a [routerLinkActive]="'active'" class="resNavItem" data-toggle="tooltip" data-placement="top"
					title="Contains basic instructions to use STiR" href="#" routerLink="/home">Home</a>
			</res-navigation-item>
			<res-navigation-group name="Manage Recipes">
				<res-navigation-item>
					<a [routerLinkActive]="'active'" class="resNavItem groupItem" data-toggle="tooltip"
						data-placement="top" title="New Recipe" href="#" routerLink="/manage-recipes/recipe">New
						Recipe</a>
				</res-navigation-item>
				<res-navigation-item>
					<a [routerLinkActive]="'active'" class="resNavItem groupItem" data-toggle="tooltip"
						data-placement="top" title="Find Recipes" href="#"
						routerLink="/manage-recipes/find-recipes">Find
						Existing Recipe</a></res-navigation-item>
			</res-navigation-group>
			<!-- <res-navigation-item>
				<a [routerLinkActive]="'active'" class="resNavItem" data-toggle="tooltip" data-placement="top"
					title="Manage Preferences" href="*" routerLink="/preferences">Preferences</a>
			</res-navigation-item> -->
			<res-navigation-item>
				<a [routerLinkActive]="'active'" class="resNavItem" data-toggle="tooltip" data-placement="top"
					title="Manage Ingredients" href="*" routerLink="/ingredients">Ingredients</a>
			</res-navigation-item>
			<res-navigation-item>
				<a [routerLinkActive]="'active'" class="resNavItem" data-toggle="tooltip" data-placement="top"
					title="Manage Standards" href="*" routerLink="/manage-standards">Manage Standards</a>
			</res-navigation-item>
			<res-navigation-group *ngIf="isSTiRAdmin" name="Admin">
				<res-navigation-item><a [routerLinkActive]="'active'" class="resNavItem groupItem" data-toggle="tooltip"
						data-placement="top" title="Manage Basic Code" routerLink="/admin/manage-basic-code"
						href="#">Manage Basic
						Code</a></res-navigation-item>
				<res-navigation-item>
					<a [routerLinkActive]="'active'" class="resNavItem groupItem" data-toggle="tooltip"
						data-placement="top" title="Prism Seed Treatment" href="#"
						routerLink="/admin/prism-seed-treatment">Prism Seed Treatment</a></res-navigation-item>
			</res-navigation-group>
			<i (mouseenter)="op2.show($event)" class="pi pi-info-circle" style="font-size: 19px; color: #0072CE;"></i>
		</res-navigation>
		<router-outlet></router-outlet>
		<res-footer>
			<div res-footer-content>
			</div>
			<div res-footer-application-details>
				<div>STiR Version : {{versionNumber}}</div>
				<div>Internal Use Only</div>
			</div>
		</res-footer>
	</res-layout>
	<app-loading [imageSrc]="imageSrc" [debounceTime]="300"></app-loading>
	<p-overlayPanel #op2 [showCloseIcon]="true" [dismissable]="true" appendTo="body">
		<ng-template pTemplate="header">
			<div class="grid" style="height: 30px,">
				<div class="col-8">
					<label style="font-weight: bold; font-size: large; margin-left: 15px;">App Messages</label>
				</div>
				<div style="margin-top: 10px;">
					<label style="font-size: 14px;">{{this.environment}} Version: {{versionNumber}}</label>
				</div>
			</div>
		</ng-template>
		<ng-template pTemplate="content">
			<p-table [value]="errors" [style]="{width: '600px', padding: '10px'}">
				<ng-template pTemplate="caption">
					<div style="text-align:left">
						<button pButton type="button" (click)="clearErrors()"
							style="width: 50px;height: 25px; padding: 3px;" class="ui-button-info">Clear</button>
					</div>
				</ng-template>
				<ng-template pTemplate="header">
					<tr>
						<th style="padding-left: 2px; width: 60px;">Summary</th>
						<th style="padding-left: 2px;">Details</th>
						<th style="padding-left: 2px; width: 60px;">Time</th>
						<th style="padding-left: 5px; width: 60px;">Image</th>
					</tr>
				</ng-template>
				<ng-template pTemplate="body" let-rowData>
					<tr [ngStyle]="{'background-color': bgColor(rowData), 'color': color(rowData) }">
						<td style="padding-left: 2px;">{{rowData.summary}}</td>
						<td style="padding-left: 2px;">{{rowData.detail}}</td>
						<td style="padding-left: 2px;">{{rowData.dateTime}}</td>
						<td class="imgAlign"><img *ngIf="rowData.img" src="{{rowData.img}}" width="50" height="50"
								(click)="downloadImage(rowData)"></td>
					</tr>
				</ng-template>
			</p-table>
		</ng-template>
	</p-overlayPanel>

</span>