import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { ResearchComponentsMsalIntegrationModule } from '@corteva-research/ngx-components-msal-integration';
import { ResearchComponentsCoreModule } from '@corteva-research/ngx-components-core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MsalService, MsalGuard, MsalBroadcastService, MsalRedirectComponent,MsalInterceptor } from '@azure/msal-angular';
import { EditorModule } from 'primeng/editor';
import { ButtonModule } from 'primeng/button';
import { DropdownModule } from 'primeng/dropdown';
import { ListboxModule } from 'primeng/listbox';
import { MultiSelectModule } from 'primeng/multiselect';
import { PanelModule } from 'primeng/panel';
import { InputSwitchModule } from 'primeng/inputswitch';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { CheckboxModule } from 'primeng/checkbox';
import { CalendarModule } from 'primeng/calendar';
import { TableModule } from 'primeng/table';
import { DialogModule } from 'primeng/dialog';
import { ToastModule } from 'primeng/toast';
import { RadioButtonModule } from 'primeng/radiobutton';
import { TabMenuModule } from 'primeng/tabmenu';
import { AgGridModule } from 'ag-grid-angular';
import { MessageService } from 'primeng/api';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogService, DynamicDialogModule } from 'primeng/dynamicdialog';
import { ConfirmationService } from 'primeng/api';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { ChipsModule } from 'primeng/chips';
import { CardModule } from 'primeng/card';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { AppComponent } from './app.component';
import { HomeComponent } from './components/home/home.component';
import { WINDOW_PROVIDERS } from './shared/windowprovider';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { PagenotfoundComponent } from './components/pagenotfound/pagenotfound.component';
import { ColorPickerModule } from 'primeng/colorpicker';
import { SetCustomColorComponent } from './shared/setCustomColor';
import { UnderconstructionComponent } from './components/underconstruction/underconstruction.component';
import { DatePipe, DecimalPipe } from '@angular/common';
import { SharedModule } from './shared/shared.module';
import { ErrorInterceptor } from './interceptor/error-interceptor';
const isIE = window.navigator.userAgent.indexOf("MSIE ") > -1 || window.navigator.userAgent.indexOf("Trident/") > -1;

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    PagenotfoundComponent,
    SetCustomColorComponent,
    UnderconstructionComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ButtonModule,
    DropdownModule,
    MultiSelectModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    EditorModule,
    PanelModule,
    InputSwitchModule,
    AutoCompleteModule,
    TabMenuModule,
    ListboxModule,
    CheckboxModule,
    DialogModule,
    TableModule,
    ToastModule,
    CalendarModule,
    SharedModule,
    RadioButtonModule,
    OverlayPanelModule,
    ConfirmDialogModule,
    DynamicDialogModule,
    InputTextareaModule,
    TypeaheadModule.forRoot(),
    ChipsModule,
    CardModule,
    ClipboardModule,
    ColorPickerModule,
    ResearchComponentsCoreModule.forRoot('stir-ui'),
    ResearchComponentsMsalIntegrationModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    MessageService,
    DialogService,
    ConfirmationService,
    WINDOW_PROVIDERS,
    DatePipe,
    DecimalPipe
  ],
  bootstrap: [AppComponent, MsalRedirectComponent]
})
export class AppModule { }
